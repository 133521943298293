import React from "react";
import styled from "styled-components";

export default function CookiesDisclaimer({open, onClose}) {
  return (
    <Container open={open}>
      <Content>
        <p>
          Diese Webseite verwendet Cookies. Cookies ermöglichen uns zu verstehen
          wie Besucher unsere Webseite nutzen, damit wir sie verbessern und
          Ihnen das bestmögliche Erlebnis bieten können.
        </p>
        <button onClick={onClose}>Okay</button>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  display: ${(props) => (props.open ? "block" : "none")};
  position: fixed;
  width: 100%;
  padding: 0.5rem 0.5rem;
  bottom: 0;
`;

const Content = styled.div`
  background-color: ${(props) => props.theme.colors.secondary};
  padding: 1rem 2rem;
  color: white;
  border-radius: 0rem 2rem 0rem 2rem;
  display: flex;
  align-items: center;

  & > p {
    margin-right: auto;
  }

  & > button {
    background-color: ${(props) => props.theme.colors.primary};
    font-family: ${(props) => props.theme.fonts.primary};
    border: 0;
    font-size: 1.3rem;
    color: white;
    padding: 0.5rem 1rem;
  }
`;
