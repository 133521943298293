import React from "react";

import styled from "styled-components";
import ProductHeader from "./ProductHeader";
import ProductDetails from "./ProductDetails/ProductDetails";

import {product} from "../../data/product";
import {mq} from "../../helpers/constants";

const ProductContainer = () => {
  return (
    <Container>
      <ProductHeader title={product.title} subtitle={product.subtitle} />
      <ProductDetails
        image={product.image}
        tagList={product.tagList}
        descriptionList={product.descriptionList}
        documentLinkList={product.documentLinkList}
        contactButton={product.contactButton}
      />
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  ${mq.xl} {
    width: 48%;
  }
`;

export default ProductContainer;
