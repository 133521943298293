import React from "react";
import styled from "styled-components";

const ProductTag = ({image}) => {
  const {src, alt} = image;
  return <Image src={src} alt={alt} />;
};

const Image = styled.img`
  width: 4rem;
  margin: 0.25rem;
`;

export default ProductTag;
