import React from "react";

import {mq} from "../helpers/constants";

import heroImage from "../assets/images/laughing_people_cropped.jpg";
import styled from "styled-components";

export default function Hero() {
  return (
    <Container>
      <Image src={heroImage} alt="Happy Customers" />
      <Overlay>
        <OverlaySection>
          <h4>Effetkiver Schutz</h4>
          <h2>für alle</h2>
        </OverlaySection>
        <OverlaySection>
          <h4>effective protection</h4>
          <h2>for everyone</h2>
        </OverlaySection>
      </Overlay>
      <ParagraphsContainer>
        <Paragraph>
          Das regelmäßige Hände desinfizieren schützt Sie und Ihre Mitmenschen
          vor der Übertragung vieler Viren, Bakterien und Keime. Übernehmen Sie
          Verantwortung.
        </Paragraph>
        <Paragraph>
          Regular hand disinfection protects you and your fellow human beings
          from the transmission of many viruses, bacteria and germs. Take
          responsibility.
        </Paragraph>
      </ParagraphsContainer>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  ${mq.xl} {
    width: 50%;
    margin-right: auto;
  }
`;

const Image = styled.img`
  ${mq.lg} {
    width: 92%;
  }
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 0 4rem 0 4rem;
`;

const Overlay = styled.div`
  display: flex;
  align-items: center;

  padding: 2rem;

  border-radius: 0 4rem 0 4rem;
  margin-top: -4rem;
  position: relative;
  color: white;
  background-color: rgba(32, 32, 32, 0.7);
  flex-direction: column;

  ${mq.sm} {
    flex-direction: row;
    padding: 2.5rem 4rem 2.5rem 4rem;
  }
`;

const OverlaySection = styled.div`
  width: 100%;
  text-align: center;

  &:not(:last-child) {
    margin-bottom: 1.5rem;
    ${mq.sm} {
      margin-bottom: 0;
    }
  }

  ${mq.sm} {
    text-align: right;
  }

  h4 {
    font-size: 1rem;
    font-weight: 500;
    font-family: ${(props) => props.theme.fonts.primary};
    ${mq.md} {
      font-size: 1.5rem;
    }
  }

  h2 {
    font-size: 1.5rem;
    font-weight: 600;
    font-family: ${(props) => props.theme.fonts.primary};
    ${mq.md} {
      font-size: 2.25rem;
    }
  }
`;

const Paragraph = styled.p`
  padding: 1rem;
  font-family: ${(props) => props.theme.fonts.body};
`;

const ParagraphsContainer = styled.div`
  font-size: 1.2rem;
  margin-top: 1rem;
  display: flex;
  text-align: justify;
  flex-direction: column;
  ${mq.sm} {
    flex-direction: row;
  }
`;
