import React from "react";

export default React.createContext({
  contactModalOpen: false,
  setContactModalOpen: () => {},
  generalDisclaimerModalOpen: false,
  setGeneralDisclaimerModalOpen: () => {},
  privacyInformationModalOpen: false,
  setPrivacyInformationModalOpen: () => {},
});
