import React from "react";
import styled from "styled-components";
import {mq} from "../../helpers/constants";

const stopPropagation = (e) => {
  e.stopPropagation();
};

export default function Modal({open, onClose, children}) {
  return (
    <Backdrop open={open} onClick={onClose}>
      <Container onClick={stopPropagation}>
        <ModalCloseButton onClick={onClose} />
        <Content>{children}</Content>
      </Container>
    </Backdrop>
  );
}

const ModalCloseButton = styled.div`
  width: 30px;
  height: 30px;
  position: absolute;
  justify-content: center;
  flex-direction: column;
  display: flex;
  right: 10px;
  top: 10px;
  cursor: pointer;

  &::after,
  &::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 5px;
    display: block;
    background-color: ${(props) => props.theme.colors.secondary};
  }

  &::after {
    transform: rotate(45deg);
  }

  &::before {
    transform: rotate(-45deg);
  }
`;

const Backdrop = styled.div`
  display: ${(props) => (props.open ? "flex" : "none")};
  position: fixed;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
`;

const Container = styled.div`
  position: relative;
  z-index: 5;
  width: 40rem;
  max-width: 40rem;
  width: 100%;
  height: 40rem;
  max-height: 40rem;
  margin: 1rem 2rem 1rem 1rem;
  background-color: white;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;

  padding: 1rem;
  ${mq.md} {
    padding: 4rem;
  }
  overflow-y: scroll;
  overflow-x: hidden;
`;
