import React from "react";
import styled from "styled-components";
import {mq} from "../../helpers/constants";

const ProductTitle = ({text}) => {
  return <Heading>{text}</Heading>;
};

const Heading = styled.h1`
  ${mq.md} {
    text-align: right;
  }
  width: 100%;
  text-align: center;
  letter-spacing: 5px;
  font-family: ${(props) => props.theme.fonts.primary};
  color: ${(props) => props.theme.colors.primary};
  font-size: 2.3rem;
  font-weight: 600;
`;

export default ProductTitle;
