import React from "react";
import styled from "styled-components";
import {mq} from "../helpers/constants";

import GeneralDisclaimerModal from "./Modals/GeneralDisclaimerModal";
import ContactModal from "./Modals/ContactModal";
import PrivacyInformationModal from "./Modals/PrivacyInformationModal";
import ModalsManagementContext from "./Modals/ModalsManagementContext";

export default function Footer() {
  return (
    <ModalsManagementContext.Consumer>
      {({
        setContactModalOpen,
        setGeneralDisclaimerModalOpen,
        setPrivacyInformationModalOpen,
        generalDisclaimerModalOpen,
        contactModalOpen,
        privacyInformationModalOpen,
      }) => (
        <>
          <Container>
            <Content>
              <Copyright>©easyDesinfect 2020</Copyright>
              <Menu>
                <div onClick={() => setContactModalOpen(true)}>Kontakt</div>
                <div onClick={() => setGeneralDisclaimerModalOpen(true)}>
                  Impressum
                </div>
                <div onClick={() => setPrivacyInformationModalOpen(true)}>
                  Datenschutz
                </div>
              </Menu>
            </Content>
          </Container>

          <GeneralDisclaimerModal
            open={generalDisclaimerModalOpen}
            onClose={() => setGeneralDisclaimerModalOpen(false)}
          />

          <ContactModal
            open={contactModalOpen}
            onClose={() => setContactModalOpen(false)}
          />

          <PrivacyInformationModal
            open={privacyInformationModalOpen}
            onClose={() => setPrivacyInformationModalOpen(false)}
          />
        </>
      )}
    </ModalsManagementContext.Consumer>
  );
}

const Container = styled.div`
  width: 100%;
  padding: 0rem;
  background-color: ${(props) => props.theme.colors.primary};
`;

const Content = styled.div`
  max-width: 768px;
  margin-left: auto;
  margin-right: auto;
  display: flex;

  flex-direction: column;

  justify-content: space-between;
  align-items: center;

  ${mq.lg} {
    flex-direction: row;
    max-width: 1440px;
  }
`;

const Menu = styled.div`
  display: flex;
  & > div {
    padding: 0.25rem 1rem;
    cursor: pointer;
    font-size: 1.2rem;
    color: white;
  }
`;

const Copyright = styled.div`
  color: white;
`;
