import React, {Component} from "react";
import styled from "styled-components";
import axios from "axios";
import iconCheckmark from "../assets/images/checkmark-outline.svg";
import {mailUrl} from "../helpers/constants";
import {ToastsContainer, ToastsStore} from "react-toasts";
import ModalsManagementContext from "./Modals/ModalsManagementContext";

const initialFormState = {
  company: "",
  name: "",
  email: "",
  phone: "",
  message: "",
  agreement: false,
};

export default class ContactForm extends Component {
  state = {
    form: {
      ...initialFormState,
    },
  };

  static contextType = ModalsManagementContext;

  resetFormState = () => {
    this.setState({...this.state, form: {...initialFormState}});
  };

  handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(mailUrl, this.state.form)
      .then(() => {
        this.resetFormState();
        ToastsStore.success(
          "Vielen Dank, Ihre Nachricht wurde an uns übermittelt und wir werden uns zeitnah darum kümmern."
        );
        this.context.setContactModalOpen(false);
      })
      .catch((err) => {
        console.log(err);
        ToastsStore.error("There was a problem with sending email");
      });
  };

  onInputChange = (e) => {
    let value = "";

    if (e.target.type === "checkbox") value = e.target.checked;
    else value = e.target.value;

    this.setState({
      ...this.state,
      form: {...this.state.form, [e.target.name]: value},
    });
  };

  render() {
    const {company, name, email, phone, message, agreement} = this.state.form;
    return (
      <>
        <ToastsContainer store={ToastsStore} position="top_center" />
        <Form onSubmit={this.handleSubmit}>
          <InputGroup>
            <label htmlFor="company">Firma:</label>
            <input
              id="company"
              name="company"
              type="text"
              required
              onChange={this.onInputChange}
              value={company}
            />
          </InputGroup>
          <InputGroup>
            <label htmlFor="name">Name:</label>
            <input
              id="name"
              name="name"
              type="text"
              required
              onChange={this.onInputChange}
              value={name}
            />
          </InputGroup>
          <InputGroup>
            <label htmlFor="email">E-Mail:</label>
            <input
              id="email"
              name="email"
              type="email"
              required
              onChange={this.onInputChange}
              value={email}
            />
          </InputGroup>
          <InputGroup>
            <label htmlFor="phone">Telefon:</label>
            <input
              id="phone"
              name="phone"
              type="text"
              onChange={this.onInputChange}
              value={phone}
            />
          </InputGroup>
          <Divider />
          <TextGroup>
            <label htmlFor="message">Nachricht:</label>
            <textarea
              id="message"
              name="message"
              rows="8"
              required
              onChange={this.onInputChange}
              value={message}
            />
          </TextGroup>
          <Divider />
          <CheckboxGroup>
            <input
              id="agreement"
              name="agreement"
              type="checkbox"
              required
              onChange={this.onInputChange}
              checked={agreement}
            />
            <label htmlFor="agreement">
              Ich habe die{" "}
              <Link
                onClick={() =>
                  this.context.setPrivacyInformationModalOpen(true)
                }
              >
                Informationen zum Datenschutz
              </Link>{" "}
              zur Kenntnis genommen.
              <br />I have read the{" "}
              <Link
                onClick={() =>
                  this.context.setPrivacyInformationModalOpen(true)
                }
              >
                privacy policy
              </Link>
              .
            </label>
          </CheckboxGroup>
          <Divider />
          <Button type="submit">Senden / Send</Button>
          <Divider />
        </Form>
      </>
    );
  }
}

const Form = styled.form``;

const InputGroup = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
  font-size: 1.2rem;

  & > label {
    width: 25%;
    color: ${(props) => props.theme.colors.primary};
  }

  & > input {
    font-size: 1.2rem;
    background-color: ${(props) => props.theme.colors.secondary};
    color: white;
    border: 0;
    padding: 0.5rem 1rem;
    width: 75%;
  }
`;

const TextGroup = styled.div`
  margin-bottom: 0.5rem;
  font-size: 1.2rem;

  & > label {
    display: block;
    margin-bottom: 0.75rem;
    color: ${(props) => props.theme.colors.primary};
  }

  & > textarea {
    font-size: 1.2rem;
    background-color: ${(props) => props.theme.colors.secondary};
    color: white;
    border: 0;
    padding: 0.5rem 1rem;
    width: 100%;
  }
`;

const CheckboxGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > label {
    font-size: 1.2rem;
    display: block;
    color: ${(props) => props.theme.colors.primary};
    width: 90%;
  }

  & > input {
    margin-left: auto;
    margin-right: auto;
    display: block;
    appearance: none;

    width: 20px;
    height: 20px;

    border: 4px solid ${(props) => props.theme.colors.secondary};

    &:focus {
      outline: 0;
    }

    &:checked::after {
      content: "";
      background-image: url(${iconCheckmark});
      background-size: cover;
      display: block;
      width: 40px;
      height: 40px;
      transform: translate(-10px, -20px);
    }
  }
`;

const Divider = styled.div`
  padding-top: 2rem;
`;

const Link = styled.span`
  text-decoration: underline;
  color: inherit;
`;

const Button = styled.button`
  display: block;
  background-color: ${(props) => props.theme.colors.secondary};
  border: 0;
  padding: 0.5rem 1.5rem;
  font-size: 1.3rem;
  color: white;
  font-family: ${(props) => props.theme.fonts.primary};
  font-weight: 500;
  margin-left: auto;
  margin-right: auto;
`;
