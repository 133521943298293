import React from "react";
import Modal from "./Modal";
import styled from "styled-components";

export default function GeneralDisclaimerModal({open, onClose}) {
  return (
    <Modal open={open} onClose={onClose}>
      <Heading>Impressum</Heading>
      <Paragraph>
        <b>Herausgegeben von:</b>
        <br />
        innapp Paul Roskosch
        <br />
        Inh. Paul Roskosch
        <br />
        Antonie-Maurer-Str. 14
        <br />
        D-61169 Friedberg (Hessen)
        <br />
        <br />
        USt.Nr. DE287523935
        <br />
        <br />
        <b>Kontakt:</b>
        <br />
        Mario Eppler
        <br />
        phone +49 160 96 444 110
        <br />
        mail <a href="mailto:info@easydesinfect.com">info@easydesinfect.com</a>
        <br />
        <br />
        <b>Verantwortlich für den Inhalt:</b>
        <br /> Paul Roskosch <br />
        <br />
        <b>HAFTUNGSHINWEIS</b>
        <br />
        Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung
        für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten
        sind ausschließlich deren Betreiber verantwortlich.
      </Paragraph>
    </Modal>
  );
}

const Heading = styled.h1`
  font-family: ${(props) => props.theme.fonts.primary};
  color: ${(props) => props.theme.colors.primary};
  font-weight: 500;
  margin-bottom: 2rem;
`;

const Paragraph = styled.p`
  color: ${(props) => props.theme.colors.primary};
  font-size: 1.2rem;
  text-align: justify;
  line-height: 150%;
  margin-bottom: 4rem;
`;
