// Assets
import productImage from "../assets/images/products.PNG";
import gerFlag from "../assets/images/flag_gr.png";
import enFlag from "../assets/images/flag_uk.png";
import gerProductInfoDoc from "../assets/documents/Produktinformationen_ Hygienestation_HS_200.pdf";
import productTag1 from "../assets/images/electric-cable.png";
import productTag2 from "../assets/images/water-pipe.png";
import pdfIcon from "../assets/images/icon_download_pdf.png";
import pointingHand from "../assets/images/click hand.png";

export const product = {
  title: "Hygienestation HS-200",
  subtitle: "DIE NEUE GENERATION",
  image: {
    src: productImage,
    alt: "Hygienestation",
  },
  descriptionList: [
    "Kein Storm erforderlich",
    "Kein Wasser erforderlich",
    "Befüllung: 500ml, 1000ml, 5l, 10l, 15l, 20l, 25l",
    "Vakuum-Hochleistungspumpe",
    "Geringer Wartungsaufwand",
    "Diebstahlschutz",
  ],
  tagList: [
    {
      image: {
        src: productTag2,
        alt: "Water tank",
      },
    },
    {
      image: {
        src: productTag1,
        alt: "Battery powered",
      },
    },
  ],
  documentLinkList: [
    {
      text: "Produktinformationen",
      flagImage: {
        src: gerFlag,
        alt: "German flag",
      },
      documentImage: {
        src: pdfIcon,
        alt: "Read document in German",
      },
      href: gerProductInfoDoc,
      disabled: false,
    },
    {
      text: "Product information",
      flagImage: {
        src: enFlag,
        alt: "English flag",
      },
      documentImage: {
        src: pdfIcon,
        alt: "Read document in English",
      },
      href: null,
      disabled: true,
    },
  ],
  contactButton: {
    text: " Angebot anfragen",
    image: {
      src: pointingHand,
      alt: "Pointing hand",
    },
  },
};
