import React from "react";
import styled from "styled-components";
import {mq} from "../../../helpers/constants";

const ProductImage = ({src, alt}) => {
  return <Image src={src} alt={alt} />;
};

const Image = styled.img`
  transform: translateY(10px);
  max-width: 20rem;
  ${mq.md} {
    width: 100%;
    transform: translateY(-30px);
  }
`;

export default ProductImage;
