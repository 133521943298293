import React from "react";
import styled from "styled-components";

import ProductImage from "./ProductImage";
import ProductTagList from "./ProductTagList";
import ProductDescriptionList from "./ProductDescriptionList";
import ProductDocumentLinkList from "./ProductDocumentLinkList";
import ProductContactButton from "./ProductContactButton";
import {mq} from "../../../helpers/constants";

const ProductDetails = ({
  image,
  descriptionList,
  tagList,
  documentLinkList,
  contactButton,
}) => {
  return (
    <Container>
      <Section>
        <ProductImage src={image.src} alt={image.alt} />
      </Section>
      <Section>
        <ProductTagList tagList={tagList} />
        <ProductDescriptionList descriptionList={descriptionList} />
        <ProductDocumentLinkList documentLinkList={documentLinkList} />
        <ProductContactButton
          image={contactButton.image}
          text={contactButton.text}
        />
      </Section>
    </Container>
  );
};

const Container = styled.div`
  ${mq.md} {
    flex-direction: row;
    justify-content: space-between;
  }
  flex-direction: column;
  justify-content: center;
  display: flex;
  align-items: center;
`;

const Section = styled.div`
  ${mq.md} {
    width: 48%;
  }
`;

export default ProductDetails;
