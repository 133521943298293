import React from "react";
import styled from "styled-components";

import logo from "../assets/images/logo_text_colour.png";

import flagGerman from "../assets/images/flag_gr.png";
import flagEngland from "../assets/images/flag_uk.png";

import {mq} from "../helpers/constants";

export default function Header() {
  return (
    <Container className="bg-primary">
      <Content>
        <Logo src={logo} alt="Logo" />
        <FlagsContainer>
          <FlagContainer>
            <FlagIcon src={flagGerman} alt="German Flag" />
            <FlagText>
              <b>Vielen Dank für Ihren Besuch!</b> Wir entwickeln gerade unsere neue Website für Sie.
            </FlagText>
          </FlagContainer>
          <FlagContainer>
            <FlagIcon src={flagEngland} alt="German Flag" />
            <FlagText>
              <b>Thank you for visiting!</b> We are currently developing our new website for you.
            </FlagText>
          </FlagContainer>
        </FlagsContainer>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  overflow: hidden;
  padding: 0.5rem;
  width: 100%;
  background-color: ${(props) => props.theme.colors.primary};
`;

const Content = styled.div`
  max-width: 768px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;

  align-items: center;

  ${mq.lg} {
    flex-direction: row;
    max-width: 1440px;
  }
`;

const Logo = styled.img`
  align-self: center;
  max-width: 20rem;
  margin-bottom: 1rem;
  ${mq.md} {
    margin-right: 7rem;
  }
  ${mq.xl} {
    margin-bottom: 0;
    max-width: 20rem;
    margin-right: 15rem;
  }
`;

const FlagsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlagContainer = styled.div`
  display: flex;
  align-items: center;
  ${mq.lg} {
    height: 2rem;
  }
`;

const FlagIcon = styled.img`
  display: flex;
  margin-right: 0.5rem;
  height: 2rem;
`;

const FlagText = styled.div`
  color: white;
  font-size: 1rem;
  font-family: ${(props) => props.theme.fonts.body};
`;
