import React from "react";
import styled from "styled-components";

const ProductDocumentLink = ({
  text,
  documentImage,
  flagImage,
  href,
  disabled,
}) => {
  return (
    <Container href={href} disabled={disabled} target="_blank">
      <DocumentImage src={documentImage.src} alt={documentImage.alt} />
      <Text>{text}</Text>
      <FlagImage src={flagImage.src} alt={flagImage.alt} />
    </Container>
  );
};

const Container = styled.a`
  margin-top: 0.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: white;
  background-color: ${(props) => props.theme.colors.secondary};
  border-radius: 0rem 1.5rem 0rem 1.5rem;
  padding: 0.25rem 0.25rem;
  cursor: ${(props) => (props.disabled ? "no-drop" : "pointer")};
  filter: ${(props) => (props.disabled ? "saturate(0)" : "saturate(1)")};
`;

const Text = styled.span`
  flex: 1;
  font-size: 1.3rem;
`;

const DocumentImage = styled.img`
  width: 3.5rem;
`;

const FlagImage = styled.img`
  width: 2.5rem;
  padding: 0.25rem;
  margin-right: 1rem;
`;

export default ProductDocumentLink;
