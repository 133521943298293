import React from "react";
import styled from "styled-components";
import ProductTitle from "./ProductTitle";
import ProductSubtitle from "./ProductSubtitle";

const ProductHeader = ({title, subtitle}) => {
  return (
    <Container>
      <ProductTitle text={title} />
      <ProductSubtitle text={subtitle} />
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
`;

export default ProductHeader;
