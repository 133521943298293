import React from "react";
import styled from "styled-components";

const ProductDescriptionList = ({descriptionList = []}) => {
  return (
    <Container>
      <List>
        {descriptionList.map((description, i) => (
          <ListElement key={i}>{description}</ListElement>
        ))}
      </List>
    </Container>
  );
};

const Container = styled.div``;

const List = styled.ul`
  padding-left: 2rem;
`;

const ListElement = styled.li`
  color: ${(props) => props.theme.colors.secondary};
  font-family: ${(props) => props.theme.fonts.body};
  font-size: 1.4rem;
  font-weight: bold;
  padding: 0.35rem 0rem;
`;

export default ProductDescriptionList;
