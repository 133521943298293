import React from "react";
import styled from "styled-components";
import {mq} from "../../helpers/constants";

const ProductSubtitle = ({text}) => {
  return <SubHeading>{text}</SubHeading>;
};

const SubHeading = styled.h2`
  ${mq.md} {
    text-align: right;
  }
  width: 100%;
  text-align: center;
  font-family: ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.secondary};
  font-size: 2.3rem;
  font-weight: 600;
`;

export default ProductSubtitle;
