import React from "react";
import Modal from "./Modal";
import styled from "styled-components";
import PrivacyInformationContent from "./PrivacyInformationContent";
import {mq} from "../../helpers/constants";

export default function PrivacyInformationModal({open, onClose}) {
  return (
    <Modal open={open} onClose={onClose}>
      <Heading>Datenschutz</Heading>
      <Paragraph>
        <PrivacyInformationContent />
      </Paragraph>
    </Modal>
  );
}

const Heading = styled.h1`
  font-family: ${(props) => props.theme.fonts.primary};
  color: ${(props) => props.theme.colors.primary};
  font-weight: 500;
  margin-bottom: 2rem;
`;

const Paragraph = styled.p`
  color: ${(props) => props.theme.colors.primary};

  font-size: 0.8em;
  ${mq.md} {
    font-size: 1.2rem;
  }

  word-break: break-all;
  text-align: justify;
  line-height: 150%;
  margin-bottom: 4rem;
`;
