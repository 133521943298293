import React from "react";
import styled from "styled-components";

import ModalManagementContext from "../../Modals/ModalsManagementContext";

const ProductContactButton = ({text, image}) => {
  return (
    <ModalManagementContext.Consumer>
      {({setContactModalOpen}) => (
        <Container onClick={() => setContactModalOpen(true)}>
          <Text>{text}</Text>
          <Image src={image.src} alt={image.alt} />
        </Container>
      )}
    </ModalManagementContext.Consumer>
  );
};

const Container = styled.button`
  margin-top: 0.75rem;
  position: relative;
  width: 100%;
  display: flex;
  border: 0;
  text-align: left;
  color: white;
  background-color: ${(props) => props.theme.colors.secondary};
  border-radius: 0rem 1.5rem 0rem 1.5rem;
  padding: 0.5rem 1.25rem;
  cursor: pointer;
`;

const Text = styled.span`
  flex: 1;
  font-size: 1.7rem;
  font-weight: 800;
`;

const Image = styled.img`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 3rem;
  transform: translate(10px, 10px);
`;

export default ProductContactButton;
