export const mq = {
  xl4: `@media (min-width: 1920px)`,
  xl3: `@media (min-width: 1600px)`,
  xl2: `@media (min-width: 1440px)`,
  xl: `@media (min-width: 1280px)`,
  lg: `@media (min-width: 1024px)`,
  md: `@media (min-width: 768px)`,
  sm: `@media (min-width: 512px)`,
};

export const theme = {
  colors: {
    primary: "#515151",
    secondary: "#0175a6",
  },
  fonts: {
    primary: "Orbitron",
    body: "Calibri",
  },
};

export const mailUrl = "https://easydesinfect.com/send.php";
// export const mailUrl = "https://easydesinfect.cubiccat.pl/send.php";
