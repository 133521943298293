import React from "react";
import styled from "styled-components";

import Modal from "./Modal";
import ContactForm from "../ContactForm";

export default function ContactModal({open, onClose}) {
  return (
    <Modal open={open} onClose={onClose}>
      <Heading>Kontakt</Heading>
      <ContactForm />
    </Modal>
  );
}

const Heading = styled.h1`
  font-family: ${(props) => props.theme.fonts.primary};
  color: ${(props) => props.theme.colors.primary};
  font-weight: 500;
  margin-bottom: 2rem;
`;
