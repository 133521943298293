import React from "react";

import ProductDocumentLink from "./ProductDocumentLink";

const ProductDocumentLinkList = ({documentLinkList = []}) => {
  return (
    <div>
      {documentLinkList.map((documentLink, i) => {
        const {flagImage, href, text, disabled, documentImage} = documentLink;
        return (
          <ProductDocumentLink
            key={i}
            flagImage={flagImage}
            href={href}
            text={text}
            disabled={disabled}
            documentImage={documentImage}
          />
        );
      })}
    </div>
  );
};

export default ProductDocumentLinkList;
