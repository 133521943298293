import React from "react";
import styled from "styled-components";

import {mq} from "../helpers/constants";

import Hero from "./Hero";
import ProductContainer from "./Product/ProductContainer";

export default function Main() {
  return (
    <Container>
      <Content>
        <Hero />
        <ProductContainer />
      </Content>
    </Container>
  );
}

const Container = styled.div`
  padding: 1rem;
  width: 100%;
  flex-grow: 1;
  display: flex;
`;

const Content = styled.div`
  max-width: 768px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  margin-top: 0rem;

  ${mq.xl} {
    flex-direction: row;
    max-width: 1440px;
  }

  ${mq.xl3} {
    margin-top: 1rem;
  }
`;
