import React, {useState, useEffect} from "react";
import styled, {ThemeProvider} from "styled-components";

import {theme} from "../helpers/constants";

import Header from "./Header";
import Footer from "./Footer";
import Main from "./Main";
import CookiesDisclaimer from "./CookiesDisclaimer";
import ModalsManagementContext from "./Modals/ModalsManagementContext";

function App() {
  // COOKIES MANAGEMENT
  const [cookiesAccepted, setCookiesAccepted] = useState(false);

  useEffect(() => {
    const cookiesLocalStorage = localStorage.getItem("cookies");
    if (cookiesLocalStorage) acceptCookies();
  }, []);

  const acceptCookies = () => {
    localStorage.setItem("cookies", true);
    setCookiesAccepted(true);
  };

  // MODALS MANAGEMENT
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [generalDisclaimerModalOpen, setGeneralDisclaimerModalOpen] = useState(
    false
  );
  const [
    privacyInformationModalOpen,
    setPrivacyInformationModalOpen,
  ] = useState(false);

  return (
    <ThemeProvider theme={theme}>
      <ModalsManagementContext.Provider
        value={{
          contactModalOpen,
          generalDisclaimerModalOpen,
          privacyInformationModalOpen,
          setContactModalOpen: (state) => setContactModalOpen(state),
          setGeneralDisclaimerModalOpen: (state) =>
            setGeneralDisclaimerModalOpen(state),
          setPrivacyInformationModalOpen: (state) =>
            setPrivacyInformationModalOpen(state),
        }}
      >
        <Container>
          <Header />
          <Main />
          <Footer />
          <CookiesDisclaimer open={!cookiesAccepted} onClose={acceptCookies} />
        </Container>
      </ModalsManagementContext.Provider>
    </ThemeProvider>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f1f1f1;
`;

export default App;
