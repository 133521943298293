import React from "react";
import ProductTag from "./ProductTag";
import styled from "styled-components";

const ProductTagList = ({tagList}) => {
  return (
    <Container>
      {tagList.map((tag, i) => (
        <ProductTag key={i} image={tag.image} />
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
`;

export default ProductTagList;
